import { REDIRECT_URL_STORAGE_KEY } from '@/const';
import { initSalesPortalApi } from '@/plugins/api-client';
import type { ISalesFrontEndConfig } from '@/types';
import { createAuth0, useAuth0 } from '@auth0/auth0-vue';
import '@condo/ui/build/style.css';
import { createGtm, useGtm } from '@gtm-support/vue-gtm';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { type ObjectPlugin, createApp } from 'vue';
import VueGoogleMaps from 'vue-google-maps-community-fork';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { useCookies } from 'vue3-cookies';
import './assets/scss/all.scss';
import { exposeAuth0 } from './plugins/auth0-client';
import { router } from './router';
import { store } from './store';

/*ICONS: https://www.xicons.org */
const initApp = (config: ISalesFrontEndConfig, isProduction: boolean) => {
    const App = defineAsyncComponent(() => import('./App.vue'));
    const app = createApp(App);

    app.provide('portalVersion', config.HOUSEHOLD_VERSION);
    app.provide('enableDocumentRequestFeature', config.F_ENABLE_DOCUMENT_REQUEST);
    app.use(router);
    app.use(store);

    app.use(VueQueryPlugin, {
        queryClientConfig: {
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                    retry: 0,
                },
            },
        },
    });

    app.use(
        createAuth0({
            domain: config.AUTH0_DOMAIN,
            clientId: config.AUTH0_CLIENT_ID,
            authorizationParams: {
                redirect_uri: `${window.location.origin}/estates`,
                audience: config.AUTH0_AUDIENCE,
                scope: 'openid profile email',
            },
        }) as ObjectPlugin,
    )
        .use(exposeAuth0())
        .use(Toast);

    // a bit ugly workaround for auth0 since it doesn't allow
    if (!['/estates', '/', '/sign-up'].includes(window.location.pathname)) {
        localStorage.setItem(REDIRECT_URL_STORAGE_KEY, window.location.pathname);
    }

    initSalesPortalApi(config.S_SALES_PORTAL_API_URL);

    app.use(VueGoogleMaps, { load: { key: 'AIzaSyCFh64tk9CmYyqVrZ1q06WyQxfu6ZIz5Pg', libraries: ['places'] } });

    app.use(
        createGtm({
            id: config.GTM_ID,
            defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
            compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
            //@ts-ignore
            enabled: window.Cookiebot?.consent?.statistics ?? false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
            debug: !isProduction, // Whether or not display console logs debugs (optional)
            loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
            vueRouter: router as any, // Pass the router instance to automatically sync with router (optional)
            vueRouterAdditionalEventData: () => {
                const { user } = useAuth0();
                const { cookies } = useCookies();
                const cid = cookies.get('_ga')?.replace('GA1.1.', '');
                const isInternalEmail = ['condogroup.de', 'hh-lg.de', 'reneo.de'].some(ending => user.value?.email?.endsWith(ending)) || false;

                return { user_id: user.value?.email ?? cid, user_type: isInternalEmail ? 'internal' : 'external' };
            },
            ignoredViews: [], // Don't trigger events for specified router names (optional)
            trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
        }),
    );

    window.addEventListener(
        'CookiebotOnAccept',
        () => {
            //@ts-ignore
            if (window.Cookiebot?.consent?.statistics) {
                const gtm = useGtm();
                gtm?.enable(true);
            }
        },
        false,
    );

    return app;
};

(async () => {
    const isProduction = (import.meta as Record<string, any>).env.MODE === 'production';
    const response = await fetch('/config');
    const configObj: ISalesFrontEndConfig = await response.json();

    // base-check against latest sales-portal version, does a full refresh when it changes
    /*const prevVersion = localStorage.getItem('__VERSION__');
    if (isProduction && prevVersion && configObj.householdVersion !== prevVersion) {
        console.log('Base-version changed, reloading page to get latest version');
        location.reload();
    }
    localStorage.setItem('__VERSION__', configObj.householdVersion);*/

    const app = initApp(configObj, isProduction);
    app.mount(document.body);
})();
